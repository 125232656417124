.cTable {
    border-spacing: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

.cTable caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

.cTable tr {
    padding: .35em;
}

.cTable td {
    border-bottom: 0.5px solid rgba(34, 36, 38, .1);
    border-left: 0.5px solid rgba(34, 36, 38, .1);
    word-wrap: break-word;
}

.cTable td:first-child {
    border-left: none;
}

.cTable th,
.cTable td {
    padding: 2em;
    text-align: center;
}


.cTable th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
    border-top: 0.5px solid rgba(34, 36, 38, .1);
    border-bottom: 0.5px solid rgba(34, 36, 38, .1);
}


.cTable th:first-child {
    border-left: 0.5px solid rgba(34, 36, 38, .1);
}

.cTable th:last-child {
    border-right: 0.5px solid rgba(34, 36, 38, .1);
}

.cTable td:first-child {
    border-left: 0.5px solid rgba(34, 36, 38, .1);
}

.cTable td:last-child {
    border-right: 0.5px solid rgba(34, 36, 38, .1);
}

@media screen and (max-width: 600px) {
    .cTable {
        border: 0.5px solid rgba(34, 36, 38, .1);
    }

    .cTable caption {
        font-size: 1.3em;
    }

    .cTable thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

    }

    .cTable tr {
        display: block;
        margin-bottom: .625em;
        border-bottom: 0.5px solid rgba(34, 36, 38, .1);
    }

    .cTable tr:last-child {
        border-bottom: none;
    }

    .cTable td {
        display: block;
        font-size: .8em;
        text-align: right;
        border: none !important;

    }

    .cTable td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        border: none;
    }

    .cTable td:last-child {
        border: none;
    }
}